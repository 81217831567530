// ===========================================================================
//
// Mixins
//
// ===========================================================================


// Units
// ---------------------------------------------------------------------------

@function unit($value) {
    @return (($gutter / 2) * $value);
}


// Transitions
// ---------------------------------------------------------------------------

@mixin transition($property: all) {
    transition: $property $transition-easing-default $transition-timing-default;
}


// Shadows
// ---------------------------------------------------------------------------

@mixin paper-shadow {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.10);
};


// Scale
// ---------------------------------------------------------------------------

@function scale($value, $scale: $tablet-scale) {
    @return (round( $value * $tablet-scale ));
}
