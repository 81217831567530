// ===========================================================================
//
// Variables
//
// ===========================================================================


// Grid
// ---------------------------------------------------------------------------

$responsive: true;
$desktop-width: 1240px;
$desktop-min-width: 960px;
$gutter: 30px;
$breakpoint-mobile: 480px;
$breakpoint-desktop: 769px;
$breakpoint-tablet: $breakpoint-desktop - 1;
$tablet-scale: .65;
$mobile-scale: .5;


// Colors
// ---------------------------------------------------------------------------

// White
$real-white: #fff;
$white: darken($real-white, 3%);

// Black
$real-black: #000;
$black: lighten($real-black, 3%);

// Other
$dove-grey: #6f6f6f;
$aluminum: #9b9b9b;
$wild-sand: #f4f4f4;


// Template Mappings
// ---------------------------------------------------------------------------

$primary-color: #424149;
$primary-color-hover: darken($primary-color, 10%);
$default-type-color: $white;
$default-background-color: $wild-sand;
$border-color: $aluminum;

$skill-trainee: darken($default-type-color, 30%);


// Animation
// ---------------------------------------------------------------------------

$transition-timing-default: .15s;
$transition-easing-default: linear;
