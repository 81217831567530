// ===========================================================================
//
// Skill Pills
//
// ===========================================================================

.skill-pills {
    display: block;

    text-align: center;
}

.skill-pills__item {
    @include media(mobile) {
        margin-top: .325rem;
        margin-bottom: .325rem;
    }

    display: inline-block;

    margin-top: .5em;
    margin-bottom: .5em;
}

.skill-pill {
    @include media(mobile) {
        font-size: 13px;

        margin: 0 .125rem;
    }

    font-size: 14px;

    margin: 0 .25rem;
    padding: .25rem .875rem;

    border: 1px solid $primary-color;
    border-radius: 25px;
}

.skill-pill--has-link {
    position: relative;

    border-color: lighten($primary-color, 10%);
}

.skill-pill--is-trainee {
    color: $skill-trainee;
    // border-style: dotted;
    // border-color:  lighten($primary-color, 5%);
}

.skill-pill--has-link:hover {
    color: $real-white;
    border-color: $real-white;
}

.skill-pill--has-description {
    background-color: darken($primary-color, 3%);
}

.skill-pill--is-expert {
    position: relative;
    .skill-pill__title::before {
        position: absolute;
        top: -7px;
        left: 50%;

        width: 1px;
        height: 1px;

        content: '';

        background-color: $border-color;
        display: none;
    }
}

.skill-pill--has-description:not(.skill-pill--is-trainee) {
    // border-color: $white;
}
