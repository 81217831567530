// ===========================================================================
//
// Grid
//
// ===========================================================================


// Lost Settings
// ---------------------------------------------------------------------------

@lost gutter 30px;
// @lost flexbox flex;
// @lost cycle none;

@mixin row() {
    lost-utility: clearfix;
}


// Layout
// ---------------------------------------------------------------------------

@mixin outer-container($width: $desktop-width) {
    @include transition(padding);
    @include media(tablet) {
        padding: 0 unit(1);
    }

    padding: 0 unit(2);

    lost-center: $width;
}

@mixin standard-panel($desktop-padding: unit(8), $tablet-padding: unit(4)) {
    @include transition(padding);
    @include media(tablet) {
        padding: $tablet-padding 0 $tablet-padding 0;
    }

    padding: $desktop-padding 0 $desktop-padding 0;
};

@mixin row {
    lost-utility: clearfix;
}

@mixin clearfix {
    lost-utility: clearfix;
}

@mixin media($size) {
    @if $size == mobile and $responsive {
        @media (max-width: $breakpoint-mobile) {
            @content;
        }
    }
    @else if $size == tablet and $responsive {
        @media (max-width: $breakpoint-tablet) {
            @content;
        }
    }
    @else if $size == desktop and $responsive {
        @media (min-width: $breakpoint-desktop) {
            @content;
        }
    }
    @else if $size == desktop and not $responsive {
        @content;
    }
}

@mixin mobile-single {
    @include media(mobile) {
        lost-column: 1/1 0 0 flex;
    }
}

@mixin tablet-single {
    @include media(tablet) {
        lost-column: 1/1 0 0 flex;
    }
}

@mixin mobile-hide {
    @include media(mobile) {
        display: none;
    }
}

@mixin tablet-hide {
    @include media(tablet) {
        display: none;
    }
}

@mixin desktop-width-restriction {
    min-width: $desktop-min-width;
}

@mixin narrow-width {
    max-width: 640px;
    margin: 0 auto;
}


// Sort media queries
// ---------------------------------------------------------------------------

body {
    @include media(tablet) {
        display: block;
    }
    @include media(mobile) {
        display: block;
    }
}
