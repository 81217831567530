@charset "UTF-8";
/*! ʕノ•ᴥ•ʔノ ︵ ┻━┻ !*/
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  line-height: 1;
  margin: 0;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin-top: 0;
  margin-bottom: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -.5em;
  vertical-align: baseline;
}

strong {
  font-weight: bold;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font: inherit;
  overflow: visible;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  border: 0;
  background: none;
  -webkit-font-smoothing: inherit;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

@keyframes cycleGradient {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 100%;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes spinIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in-up {
  animation-duration: .325s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 4%, 0);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.move-in-up {
  animation-duration: .325s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: moveInUp;
}

@keyframes moveInUp {
  from {
    transform: translate3d(0, 4%, 0);
  }
  to {
    transform: none;
  }
}

.fade-in {
  animation-duration: .325s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bounce-in {
  animation: bounce-in 1000ms linear both;
}

/* Generated with Bounce.js. Edit at https://goo.gl/to5gcl */
@keyframes bounce-in {
  0% {
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.25, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1);
  }
  2.72% {
    transform: matrix3d(0.688, 0, 0, 0, 0, 0.531, 0, 0, 0, 0, 1, 0, 0, 14.825, 0, 1);
  }
  4.2% {
    transform: matrix3d(0.775, 0, 0, 0, 0, 0.662, 0, 0, 0, 0, 1, 0, 0, 8.005, 0, 1);
  }
  5.45% {
    transform: matrix3d(0.837, 0, 0, 0, 0, 0.755, 0, 0, 0, 0, 1, 0, 0, 2.68, 0, 1);
  }
  8.17% {
    transform: matrix3d(0.938, 0, 0, 0, 0, 0.907, 0, 0, 0, 0, 1, 0, 0, -5.659, 0, 1);
  }
  8.31% {
    transform: matrix3d(0.942, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, -5.934, 0, 1);
  }
  10.89% {
    transform: matrix3d(0.997, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, -8.309, 0, 1);
  }
  12.51% {
    transform: matrix3d(1.016, 0, 0, 0, 0, 1.024, 0, 0, 0, 0, 1, 0, 0, -7.605, 0, 1);
  }
  14.01% {
    transform: matrix3d(1.026, 0, 0, 0, 0, 1.039, 0, 0, 0, 0, 1, 0, 0, -5.987, 0, 1);
  }
  16.62% {
    transform: matrix3d(1.032, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, -2.34, 0, 1);
  }
  17.06% {
    transform: matrix3d(1.032, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, -1.735, 0, 1);
  }
  20.18% {
    transform: matrix3d(1.026, 0, 0, 0, 0, 1.039, 0, 0, 0, 0, 1, 0, 0, 1.561, 0, 1);
  }
  23.22% {
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 2.581, 0, 1);
  }
  27.73% {
    transform: matrix3d(1.006, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 1.257, 0, 1);
  }
  29.39% {
    transform: matrix3d(1.004, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, 0, 0.529, 0, 1);
  }
  35.56% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, -0.802, 0, 1);
  }
  38.84% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, -0.551, 0, 1);
  }
  47.89% {
    transform: matrix3d(1, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0.249, 0, 1);
  }
  60.22% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.077, 0, 1);
  }
  61.06% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.075, 0, 1);
  }
  72.55% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.024, 0, 1);
  }
  80% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  83.28% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@font-face {
  font-family: 'Catamaran';
  font-weight: 400;
  font-style: normal;
  src: url(https://fonts.gstatic.com/s/catamaran/v1/iVYgK2bOLvZcSxr0ITOecpBw1xU1rKptJj_0jans920.woff2) format("woff2");
}

@font-face {
  font-family: 'Catamaran';
  font-weight: 700;
  font-style: normal;
  src: url(https://fonts.gstatic.com/s/catamaran/v2/PpgVtUHUdnBZYNpnzGbScxampu5_7CjHW5spxoeN3Vs.woff2) format("woff2");
}

@font-face {
  font-family: 'Catamaran';
  font-weight: 800;
  font-style: normal;
  src: url(https://fonts.gstatic.com/s/catamaran/v1/6VjB_uSfn3DZ93IQv58CmBampu5_7CjHW5spxoeN3Vs.woff2) format("woff2");
}

@lost gutter 30px;
@media (max-width: 768px) {
  body {
    display: block;
  }
}

@media (max-width: 480px) {
  body {
    display: block;
  }
}

@media (max-width: 480px) {
  html,
  body {
    overflow-x: hidden;
  }
}

body {
  background-color: #424149;
}

body,
button,
input,
select,
textarea {
  font-family: "Catamaran", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #f7f7f7;
}

p,
li {
  line-height: 1.75;
  margin: 1em 0;
}

a {
  transition: all linear 0.15s;
  color: #f7f7f7;
  outline: none;
}

a:hover {
  color: #fff;
}

.page-title, .closing-title {
  font-family: "Catamaran", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.125;
  font-size: 72px;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .page-title, .closing-title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .page-title, .closing-title {
    font-size: 47px;
  }
}

.page-intro, .closing-prefix, .closing-suffix {
  font-size: 24px;
  font-weight: 600;
  color: #9b9b9b;
}

@media (max-width: 768px) {
  .page-intro, .closing-prefix, .closing-suffix {
    font-size: 18px;
  }
}

.closing-prefix {
  margin-bottom: 1rem;
}

.closing-suffix {
  margin-bottom: 2rem;
  color: #f7f7f7;
}

.page-cta {
  text-align: center;
}

.button {
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all linear 0.15s;
  display: inline-block;
  padding: 1em 4em;
  letter-spacing: 2px;
  border: 1px solid #686868;
  background-color: #424149;
}

.button.is-reversed {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
}

.button.is-reversed:hover {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.button.is-hollow {
  color: #f7f7f7;
  background-color: transparent;
}

.button.is-hollow:hover {
  color: #fff;
  border-color: #fff;
}

.container {
  transition: padding linear 0.15s;
  padding: 0 30px;
  lost-center: 1240px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}

.container--hero, .container--closing {
  padding: 0 60px;
  lost-center: 1060px;
}

@media (max-width: 768px) {
  .container--hero, .container--closing {
    padding: 0 30px;
  }
}

.container--footer {
  padding: 0 60px;
}

@media (max-width: 768px) {
  .container--footer {
    padding: 0 30px;
  }
}

.home-page .container--footer {
  lost-center: 1060px;
}

.contact-page .container--footer,
.thanks-page .container--footer {
  padding: 0 45px;
}

.standard-panel {
  transition: padding linear 0.15s;
  padding: 120px 0 120px 0;
}

@media (max-width: 768px) {
  .standard-panel {
    padding: 60px 0 60px 0;
  }
}

.is-hidden {
  display: none;
}

.turbolinks-progress-bar {
  height: 2px;
  background-color: #424149;
}

button[data-balloon] {
  overflow: visible;
}

[data-balloon] {
  position: relative;
}

[data-balloon]:after {
  filter: alpha(opactiy=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.075s ease-out 0.075s;
  -moz-transition: all 0.075s ease-out 0.075s;
  -ms-transition: all 0.075s ease-out 0.075s;
  -o-transition: all 0.075s ease-out 0.075s;
  transition: all 0.075s ease-out 0.075s;
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #fff;
  content: attr(data-balloon);
  font-size: 13px;
  padding: .5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
  font-weight: normal;
}

[data-balloon]:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  filter: alpha(opactiy=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.075s ease-out 0.075s;
  -moz-transition: all 0.075s ease-out 0.075s;
  -ms-transition: all 0.075s ease-out 0.075s;
  -o-transition: all 0.075s ease-out 0.075s;
  transition: all 0.075s ease-out 0.075s;
  content: '';
  position: absolute;
  z-index: 10;
}

[data-balloon]:hover:before, [data-balloon]:hover:after, [data-balloon][data-balloon-visible]:before, [data-balloon][data-balloon-visible]:after {
  filter: alpha(opactiy=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  pointer-events: auto;
}

[data-balloon].font-awesome:after {
  font-family: FontAwesome;
}

[data-balloon][data-balloon-break]:after {
  white-space: pre;
}

[data-balloon][data-balloon-pos="up"]:after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 11px;
  -webkit-transform: translate(-50%, 5px);
  -moz-transform: translate(-50%, 5px);
  -ms-transform: translate(-50%, 5px);
  transform: translate(-50%, 5px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up"]:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  -webkit-transform: translate(-50%, 5px);
  -moz-transform: translate(-50%, 5px);
  -ms-transform: translate(-50%, 5px);
  transform: translate(-50%, 5px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up"]:hover:after, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos="up"]:hover:before, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos='down']:after {
  left: 50%;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}

[data-balloon][data-balloon-pos='down']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 50%;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}

[data-balloon][data-balloon-pos='down']:hover:after, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos='down']:hover:before, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos='left']:after {
  margin-right: 11px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  transform: translate(10px, -50%);
}

[data-balloon][data-balloon-pos='left']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  margin-right: 5px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  transform: translate(10px, -50%);
}

[data-balloon][data-balloon-pos='left']:hover:after, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-pos='left']:hover:before, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-pos='right']:after {
  left: 100%;
  margin-left: 11px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  -moz-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
}

[data-balloon][data-balloon-pos='right']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  left: 100%;
  margin-left: 5px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  -moz-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
}

[data-balloon][data-balloon-pos='right']:hover:after, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-pos='right']:hover:before, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-length='small']:after {
  white-space: normal;
  width: 80px;
}

[data-balloon][data-balloon-length='medium']:after {
  white-space: normal;
  width: 150px;
}

[data-balloon][data-balloon-length='large']:after {
  white-space: normal;
  width: 260px;
}

[data-balloon][data-balloon-length='xlarge']:after {
  white-space: normal;
  width: 380px;
}

@media screen and (max-width: 768px) {
  [data-balloon][data-balloon-length='xlarge']:after {
    white-space: normal;
    width: 90vw;
  }
}

[data-balloon][data-balloon-length='fit']:after {
  white-space: normal;
  width: 100%;
}

.site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 45px;
  padding-bottom: 15px;
}

@media (max-width: 480px) {
  .site-header {
    padding-top: 30px;
  }
}

.main-logo {
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.75;
  display: block;
  float: left;
  margin-left: 15px;
}

.main-logo-element {
  width: 143px;
  height: 15px;
  background-repeat: no-repeat;
}

.main-logo-link {
  display: block;
  color: #f7f7f7;
}

.main-logo-link:hover {
  color: #fff;
}

.main-nav {
  display: inline-block;
  float: right;
  margin-right: 15px;
}

@media (max-width: 480px) {
  .main-nav {
    margin-right: 0;
  }
}

.main-nav-menu-item {
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 0 0 30px;
  vertical-align: top;
}

.main-nav-menu-item.is-active a {
  font-weight: 800;
  opacity: 1;
}

.main-nav-menu-item a {
  opacity: .65;
  color: #f7f7f7;
}

.main-nav-menu-item a:hover {
  opacity: 1;
  color: #fff;
}

.about-page .main-nav-menu-item a {
  opacity: .80;
}

.about-page .main-nav-menu-item:hover a, .about-page .main-nav-menu-item.is-active a {
  opacity: 1;
  color: #fff;
}

.with-inverse-header .main-nav-menu-item a {
  color: #f7f7f7;
}

.with-inverse-header .main-nav-menu-item a:hover {
  color: #f7f7f7;
}

@media (max-width: 480px) {
  .menu-item--desktop {
    display: none;
  }
}

.menu-item--mobile {
  display: none;
}

@media (max-width: 480px) {
  .menu-item--mobile {
    display: inline-block;
  }
}

.menu-item-github a {
  display: block;
}

.menu-item-github .github-icon {
  font-size: 24px;
  line-height: 18px;
  position: relative;
  top: -1px;
  display: block;
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  background-image: svg-load("../_assets/svg/github-icon.svg", fill=#fff);
}

.menu-item-twitter {
  margin-left: 15px;
}

.menu-item-twitter a {
  display: block;
}

.menu-item-twitter .twitter-icon {
  font-size: 24px;
  line-height: 18px;
  position: relative;
  top: -1px;
  display: block;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  background-image: svg-load("../_assets/svg/twitter-icon.svg", fill=#fff);
}

.menu-item-instagram {
  margin-left: 15px;
}

.menu-item-instagram a {
  display: block;
}

.menu-item-instagram .instagram-icon {
  font-size: 24px;
  line-height: 18px;
  position: relative;
  top: -1px;
  display: block;
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  background-image: svg-load("../_assets/svg/instagram-icon.svg", fill=#fff);
}

.menu-item-hashtag {
  margin-left: 5px;
  text-transform: lowercase;
}

.site-footer {
  -webkit-transition: padding;
  -moz-transition: padding;
  -ms-transition: padding;
  -o-transition: padding;
  transition: padding;
  padding: 60px 0 60px 0;
  color: #9b9b9b;
}

@media (max-width: 768px) {
  .site-footer {
    padding: 60px 0 60px 0;
  }
}

@media (max-width: 480px) {
  .site-footer {
    text-align: center;
  }
}

.footer-logo {
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.footer-logo-element {
  width: 143px;
  height: 15px;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.footer-legal {
  font-size: 14px;
  float: left;
}

@media (max-width: 480px) {
  .footer-legal {
    float: none;
  }
}

.footer-huh {
  font-size: 14px;
  display: none;
  float: right;
}

@media (min-width: 769px) {
  .home-page .footer-huh {
    display: block;
  }
}

.footer-huh:hover {
  color: #f7f7f7;
}

.footer-weird {
  font-size: 14px;
  display: block;
  clear: left;
}

.footer-weird a {
  color: #9b9b9b;
}

.footer-weird a:hover {
  color: #f7f7f7;
}

@media (max-width: 480px) {
  .footer__copyright {
    display: block;
  }
}

@media (max-width: 480px) {
  .footer__divider {
    display: none;
  }
}

.footer__greeting {
  color: #9b9b9b;
}

@media (max-width: 480px) {
  .footer__greeting {
    display: block;
  }
}

.footer__greeting:hover {
  color: #f7f7f7;
}

.webform {
  max-width: 640px;
  margin: 0 auto;
  text-align: left;
}

.webform input,
.webform textarea {
  width: 100%;
  padding: .125rem 0 1rem 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #686868;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
}

.webform input:focus, .webform input:hover,
.webform textarea:focus,
.webform textarea:hover {
  border-bottom-color: #828181;
}

.webform textarea {
  min-height: 8rem;
}

.webform-field-row {
  margin-bottom: 30px;
}

.webform-field-row:last-child {
  margin-bottom: 0;
}

.webform-field-row label.error {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 480px) {
  .webform-field-row--controls {
    text-align: center;
  }
}

.webform-field-label {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: .5em;
  display: block;
}

.webform-field-label:hover {
  cursor: pointer;
}

.webform-checkbox-group {
  padding-top: 15px;
  padding-bottom: 30px;
  border-bottom: 2px solid #9b9b9b;
  lost-flex-container: row;
}

.webform-checkbox-group:focus, .webform-checkbox-group:hover {
  border-bottom-color: #424149;
}

.webform-checkbox-label {
  position: relative;
  margin-bottom: 1rem;
  lost-column: 1/2 flex;
}

.webform-checkbox-label:hover {
  cursor: pointer;
}

.webform-checkbox-label:hover .webform-checkbox-element {
  border-color: #686868;
}

.webform-checkbox {
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
}

.webform-checkbox:checked ~ .webform-checkbox-element:before {
  background-color: #424149;
}

.webform-checkbox-element {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: .25rem;
  vertical-align: -2px;
  border: 1px solid #8e8e8e;
  border-radius: 2px;
}

.webform-checkbox-element:before {
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  border-radius: 2px;
  background-color: transparent;
}

.skill-pills {
  display: block;
  text-align: center;
}

.skill-pills__item {
  display: inline-block;
  margin-top: .5em;
  margin-bottom: .5em;
}

@media (max-width: 480px) {
  .skill-pills__item {
    margin-top: .325rem;
    margin-bottom: .325rem;
  }
}

.skill-pill {
  font-size: 14px;
  margin: 0 .25rem;
  padding: .25rem .875rem;
  border: 1px solid #424149;
  border-radius: 25px;
}

@media (max-width: 480px) {
  .skill-pill {
    font-size: 13px;
    margin: 0 .125rem;
  }
}

.skill-pill--has-link {
  position: relative;
  border-color: #5a5964;
}

.skill-pill--is-trainee {
  color: #ababab;
}

.skill-pill--has-link:hover {
  color: #fff;
  border-color: #fff;
}

.skill-pill--has-description {
  background-color: #3b3a41;
}

.skill-pill--is-expert {
  position: relative;
}

.skill-pill--is-expert .skill-pill__title::before {
  position: absolute;
  top: -7px;
  left: 50%;
  width: 1px;
  height: 1px;
  content: '';
  background-color: #9b9b9b;
  display: none;
}

.svg-scroll {
  position: absolute;
  z-index: 2;
  bottom: -140px;
  left: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 36px;
  margin-left: -12px;
  cursor: pointer;
  transition: opacity .5s;
  opacity: 0;
}

@media (max-width: 768px) {
  .svg-scroll {
    display: none !important;
  }
}

.js-svg-scroll--visible {
  opacity: 1;
}

.svg-scroll__mouse {
  width: 25px;
  transition: opacity .5s;
  vertical-align: middle;
  opacity: 1;
}

.svg-scroll__edge {
  stroke: #9b9b9b;
}

.svg-scroll__wheel {
  animation: updown 1s infinite;
  fill: #9b9b9b;
}

@keyframes updown {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.hero-panel {
  -webkit-transition: padding;
  -moz-transition: padding;
  -ms-transition: padding;
  -o-transition: padding;
  transition: padding;
  padding: 120px 0 120px 0;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
}

@media (max-width: 768px) {
  .hero-panel {
    padding: 60px 0 60px 0;
  }
}

@media (max-width: 480px) {
  .hero-panel {
    padding-bottom: 15px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .hero-panel {
    padding-top: 120px;
  }
}

@media (min-width: 769px) {
  .hero-panel {
    min-height: 650px;
  }
}

.hero-panel.is-fancy {
  color: #f7f7f7;
}

@media (min-width: 769px) {
  .hero-panel.with-paper-below {
    margin-bottom: -240px;
    padding-bottom: 240px;
  }
}

@media (min-width: 769px) {
  .home-page .hero-panel {
    min-height: 850px;
  }
}

.main-panel {
  -webkit-transition: padding;
  -moz-transition: padding;
  -ms-transition: padding;
  -o-transition: padding;
  transition: padding;
  padding: 120px 0 120px 0;
}

@media (max-width: 768px) {
  .main-panel {
    padding: 60px 0 60px 0;
  }
}

@media (min-width: 769px) {
  .main-panel.with-paper {
    margin-top: -120px;
  }
}

@media (max-width: 768px) {
  .main-panel.with-paper {
    padding-top: 15px;
  }
}

.main-panel.with-closing-panel-below {
  padding-bottom: 60px;
}

.contact-page .main-panel,
.thanks-page .main-panel {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .contact-page .main-panel,
  .thanks-page .main-panel {
    padding-top: 120px;
  }
}

@media (max-width: 480px) {
  .contact-page .main-panel,
  .thanks-page .main-panel {
    padding-top: 60px;
  }
  .contact-page .main-panel .paper,
  .thanks-page .main-panel .paper {
    padding-bottom: 0;
  }
}

@media (min-width: 769px) {
  .closing-panel {
    margin-top: 60px;
    margin-bottom: 75px;
  }
}

@media (max-width: 480px) {
  .closing-panel {
    margin-top: -60px;
    text-align: center;
  }
}

.paper {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 60px;
  border: 1px solid #686868;
  border-radius: 4px;
  lost-flex-container: row;
  justify-content: center;
}

@media (max-width: 480px) {
  .paper {
    border: none;
  }
}

@media (min-width: 769px) {
  .paper {
    padding-right: 60px;
    padding-bottom: 90px;
    padding-left: 60px;
  }
}

.paper-header__intro {
  font-size: 24px;
  font-weight: 600;
  color: #9b9b9b;
}

@media (max-width: 768px) {
  .paper-header__intro {
    font-size: 18px;
  }
}

.paper-header__title {
  font-family: "Catamaran", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 120px;
  font-weight: 800;
  line-height: 1.125;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  letter-spacing: -.25rem;
}

@media (max-width: 768px) {
  .paper-header__title {
    font-size: 78px;
    margin-top: .5rem;
    margin-bottom: .25rem;
  }
}

@media (max-width: 480px) {
  .paper-header__title {
    font-size: 64px;
  }
}

.paper-header__summary {
  font-size: 24px;
  font-weight: 600;
  color: #9b9b9b;
}

@media (max-width: 768px) {
  .paper-header__summary {
    font-size: 18px;
  }
}

.paper-box {
  position: relative;
  padding: 60px;
  text-align: center;
}

@media (max-width: 480px) {
  .paper-box {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .paper-box {
    padding: 30px;
  }
}

.paper-box.with-footer {
  padding-bottom: 90px;
}

.paper-box.with-photo-grid {
  padding: 0;
}

.paper-box.is-full {
  lost-column: 1/1 0 0 flex;
}

.paper-box.is-half {
  lost-column: 1/2 0 0 flex;
}

@media (max-width: 768px) {
  .paper-box.is-half {
    lost-column: 1/1 0 0 flex;
  }
}

.paper-box.is-third {
  lost-column: 1/3 0 0 flex;
}

@media (max-width: 480px) {
  .paper-box.is-third {
    lost-column: 1/1 0 0 flex;
  }
}

@media (max-width: 768px) {
  .paper-box.is-third {
    lost-column: 1/2 0 0 flex;
  }
}

.paper-box.is-padded {
  padding: 120px 60px;
}

.paper-box.has-topping {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .paper-box.has-topping {
    padding-top: 15px;
  }
}

.paper-box-title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: .5em;
}

.paper-box-title--primary {
  font-size: 24px;
}

.paper-box-price {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: #afafaf;
}

.paper-box-footer {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
}

.more-link {
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.circle-icon {
  -webkit-transition: background-color;
  -moz-transition: background-color;
  -ms-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  display: flex;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 50%;
  background-color: #424149;
  justify-content: center;
  align-items: center;
}

.circle-icon:hover {
  animation-duration: .325s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: spinIcon;
  background-color: #2a292e;
}

.circle-icon i {
  font-size: 20px;
  color: #fff;
}

.burger-topping {
  width: 119px;
  height: 30px;
  margin: 0 auto 1.5rem auto;
  background-repeat: no-repeat;
}

.topping-a {
  background-image: svg-load("../_assets/svg/topping-a.svg");
}

.topping-b {
  background-image: svg-load("../_assets/svg/topping-b.svg");
}

.topping-c {
  background-image: svg-load("../_assets/svg/topping-c.svg");
}

.topping-d {
  background-image: svg-load("../_assets/svg/topping-d.svg");
}

.topping-e {
  background-image: svg-load("../_assets/svg/topping-e.svg");
}

.topping-f {
  background-image: svg-load("../_assets/svg/topping-f.svg");
}

.topping-g {
  background-image: svg-load("../_assets/svg/topping-g.svg");
}

.sauce-a {
  background-image: svg-load("../_assets/svg/sauce-a.svg");
}

.sauce-b {
  background-image: svg-load("../_assets/svg/sauce-b.svg");
}

.sauce-c {
  background-image: svg-load("../_assets/svg/sauce-c.svg");
}

.cheese-a {
  background-image: svg-load("../_assets/svg/cheese-a.svg");
}

.cheese-b {
  background-image: svg-load("../_assets/svg/cheese-b.svg");
}

.burger-protein {
  width: 150px;
  height: 20px;
  margin: 0 auto 2.5rem auto;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .burger-protein {
    margin-top: 2rem;
  }
}

.protein-bun-top {
  background-image: svg-load("../_assets/svg/bun-top.svg");
  height: 61px;
}

.protein-bun-bottom {
  background-image: svg-load("../_assets/svg/bun-bottom.svg");
  height: 27px;
}

.protein-meat-a {
  background-image: svg-load("../_assets/svg/meat-a.svg");
}

.protein-meat-b {
  background-image: svg-load("../_assets/svg/meat-b.svg");
}

.protein-meat-c {
  background-image: svg-load("../_assets/svg/meat-c.svg");
}

.is-visible {
  transition: opacity .125s linear;
  animation: bounce-in 1000ms linear both;
  opacity: 1.0;
}

.is-not-visible {
  transition: opacity .125s linear;
  opacity: .0;
}

.typed-cursor {
  position: relative;
  top: 5px;
  display: inline-block;
  width: 25px;
  height: 60px;
  margin-left: .5rem;
  animation: blink 1.25s infinite;
  opacity: 1;
  background-color: white;
}

@media (max-width: 768px) {
  .typed-cursor {
    width: 16px;
    height: 39px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
}
