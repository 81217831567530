// ===========================================================================
//
// Typography
//
// ===========================================================================


// Fonts
// - https://fonts.google.com/specimen/Catamaran
//     thin 100
//     extra-light 200
//     light 300
//   * regular 400
//     medium 500
//   * semi-bold 600
//     bold 700
//   * extra-bold 800
//     black 900
// ---------------------------------------------------------------------------


// Webfonts
// ---------------------------------------------------------------------------

// @import url(https://fonts.googleapis.com/css?family=Catamaran:400,600,800);

@font-face {
    font-family: 'Catamaran';
    font-weight: 400;
    font-style: normal;

    src: url(https://fonts.gstatic.com/s/catamaran/v1/iVYgK2bOLvZcSxr0ITOecpBw1xU1rKptJj_0jans920.woff2) format('woff2');
}

@font-face {
    font-family: 'Catamaran';
    font-weight: 700;
    font-style: normal;

    src: url(https://fonts.gstatic.com/s/catamaran/v2/PpgVtUHUdnBZYNpnzGbScxampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
}

@font-face {
    font-family: 'Catamaran';
    font-weight: 800;
    font-style: normal;

    src: url(https://fonts.gstatic.com/s/catamaran/v1/6VjB_uSfn3DZ93IQv58CmBampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
}


// Variables
// ---------------------------------------------------------------------------

// Font
$helvetica: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$catamaran: 'Catamaran', $helvetica;
$default-font: $catamaran;
$title-font: $catamaran;
$default-font-size: 17px;
$default-font-weight: 400;

// Line Height
$line-height-compact: 1.25;
$line-height-normal: 1.5;
$line-height-loose: 1.75;


// Type Styles
// ---------------------------------------------------------------------------

@mixin type-default {
    font-family: $default-font;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $line-height-normal;
}

@mixin title-default {
    font-family: $title-font;
    font-weight: 700;
    line-height: 1.125;
}

@mixin type-small-caps {
    font-size: 13px;
    font-weight: 800;

    letter-spacing: 1px;
    text-transform: uppercase;
}

@mixin type-box-title {
    font-size: 20px;
    font-weight: 800;

    margin-bottom: .5em;
}
