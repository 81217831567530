// ===========================================================================
//
// Header
//
// ===========================================================================


.site-header {
    @include media(mobile) {
        padding-top: unit(2);
    }

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    padding-top: unit(3);
    padding-bottom: unit(1);
    @if not $responsive {
        @include desktop-width-restriction;
    }
}

.main-logo {
    @include type-small-caps;

    font-weight: 800;
    line-height: $line-height-loose;

    display: block;
    float: left;

    margin-left: unit(1);
}

.main-logo-element {
    width: 143px;
    height: 15px;
    // background: svg-load('../_assets/svg/logo-white.svg');

    background-repeat: no-repeat;
}

.main-logo-link {
    display: block;

    color: $white;

    &:hover {
        color: $real-white;
    }
}
