// ===========================================================================
//
// Webform
//
// ===========================================================================

.webform {
    @include narrow-width;

    text-align: left;

    input,
    textarea {
        @include transition;

        width: 100%;
        padding: .125rem 0 1rem 0;

        background-color: transparent;

        border: none;
        border-bottom: 1px solid darken($border-color, 20%);
        border-radius: 0;
        outline: none;

        -webkit-appearance: none;

        &:focus,
        &:hover {
            border-bottom-color: darken($border-color, 10%);
        }
    }

    textarea {
        min-height: 8rem;
    }
}

.webform-field-row {
    margin-bottom: unit(2);

    &:last-child {
        margin-bottom: 0;
    }

    label.error {
        display: block;

        margin-top: 10px;
        font-size: 14px;
    }
}

.webform-field-row--controls {
    @include media(mobile) {
        text-align: center;
    }
}

.webform-field-label {
    @include type-box-title;

    display: block;

    &:hover {
        cursor: pointer;
    }
}


// Checkboxes
// ---------------------------------------------------------------------------

.webform-checkbox-group {
    padding-top: unit(1);
    padding-bottom: unit(2);

    border-bottom: 2px solid $border-color;

    lost-flex-container: row;
    &:focus,
    &:hover {
        border-bottom-color: $primary-color;
    }
}

.webform-checkbox-label {
    position: relative;

    margin-bottom: 1rem;

    lost-column: 1/2 flex;

    &:hover {
        cursor: pointer;

        .webform-checkbox-element {
            border-color: darken($border-color, 20%);
        }
    }
}

.webform-checkbox {
    position: absolute;

    visibility: hidden;

    width: 0;
    height: 0;

    &:checked ~ .webform-checkbox-element:before {
        background-color: $primary-color;
    }
}

.webform-checkbox-element {
    @include transition;

    position: relative;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-right: .25rem;

    vertical-align: -2px;

    border: 1px solid darken($border-color, 5%);
    border-radius: 2px;

    &:before {
        position: absolute;
        top: 1px;
        left: 1px;

        display: block;

        width: 12px;
        height: 12px;

        content: '';

        border-radius: 2px;
        background-color: transparent;
    }
}
.webform-checkbox-value {
}
