// ===========================================================================
//
// Navigation
//
// ===========================================================================


.main-nav {
    @include media(mobile) {
        margin-right: 0;
    }

    display: inline-block;
    float: right;

    margin-right: unit(1);
}

.main-nav-menu {
}

.main-nav-menu-item {
    @include type-small-caps;

    display: inline-block;

    margin: 0 0 0 unit(2);

    vertical-align: top;


    &.is-active {
        a {
            font-weight: 800;

            opacity: 1;
        }
    }

    a {
        opacity: .65;
        color: $white;

        &:hover {
            opacity: 1;
            color: $real-white;
        }
    }

    .about-page & {
        a {
            opacity: .80;
        }

        &:hover,
        &.is-active {
            a {
                opacity: 1;
                color: $real-white;
            }
        }
    }

    .with-inverse-header & {
        a {
            color: $default-type-color;

            &:hover {
                color: $default-type-color;
            }
        }
    }
}

.menu-item--desktop {
    @include media(mobile) {
        display: none;
    }
}

.menu-item--mobile {
  display: none;
    @include media(mobile) {
        display: inline-block;
    }
}

@mixin menu-item-icon-base {
    font-size: 24px;
    line-height: 18px;

    position: relative;
    top: -1px;

    display: block;

    background-repeat: no-repeat;
}

.menu-item-github {
    a {
        display: block;
    }
    .github-icon {
        @include menu-item-icon-base;

        width: 21px;
        height: 21px;

        background-image: svg-load('../_assets/svg/github-icon.svg', fill=$real-white);
    }
}

.menu-item-twitter {
    margin-left: unit(1);
    a {
        display: block;
    }
    .twitter-icon {
        @include menu-item-icon-base;

        width: 22px;
        height: 22px;

        background-image: svg-load('../_assets/svg/twitter-icon.svg', fill=$real-white);
    }
}

.menu-item-instagram {
    margin-left: unit(1);
    a {
        display: block;
    }
    .instagram-icon {
        @include menu-item-icon-base;

        width: 21px;
        height: 21px;

        background-image: svg-load('../_assets/svg/instagram-icon.svg', fill=$real-white);
    }
}

.menu-item-hashtag {
    margin-left: 5px;

    text-transform: lowercase;
}
