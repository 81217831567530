// ===========================================================================
//
// Footer
//
// ===========================================================================


// Site Footer
// ---------------------------------------------------------------------------

.site-footer {
    @include standard-panel(unit(4));
    @include media(mobile) {
        text-align: center;
    }

    color: $aluminum;
}

.footer-logo {
    font-size: 15px;
    font-weight: 800;

    margin-bottom: unit(1);

    text-transform: uppercase;
}

.footer-logo-element {
    width: 143px;
    height: 15px;
    margin: 0 auto;
    // background: svg-load('../_assets/svg/logo.svg');

    background-repeat: no-repeat;
}

.footer-legal {
    @include media(mobile) {
        float: none;
    }

    font-size: 14px;

    float: left;
}

.footer-huh {
    @include transition;

    font-size: 14px;

    display: none;
    float: right;

    .home-page & {
        @include media(desktop) {
            display: block;
        }
    }

    &:hover {
        color: $white;
    }
}

.footer-weird {
    font-size: 14px;

    display: block;
    clear: left;

    a {
        color: $aluminum;
        &:hover {
            color: $white;
        }
    }
}


.footer__copyright {
    @include media(mobile) {
        display: block;
    }
}

.footer__divider {
    @include media(mobile) {
        display: none;
    }
}

.footer__greeting {
    @include transition;
    @include media(mobile) {
        display: block;
    }

    color: $aluminum;
    &:hover {
        color: $white;
    }
}
