// ===========================================================================
//
// Home
//
// ===========================================================================


$home-paper-offset: 16;

// Hero Panel
// ---------------------------------------------------------------------------

.hero-panel {
    @include standard-panel;
    @include media(mobile) {
        padding-bottom: unit(1);
        text-align: center;
    }
    @include media(tablet) {
        padding-top: unit(8);
    }
    @include media(desktop) {
        min-height: 650px;
    }

    display: flex;
    // @include cycle-gradient;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    align-items: center;

    &.is-fancy {
        color: $white;
    }

    &.with-paper-below {
        @include media(desktop) {
            margin-bottom: unit(-$home-paper-offset);
            padding-bottom: unit($home-paper-offset);
        }
    }
}

.home-page {
    .hero-panel {
        @include media(desktop) {
            min-height: 850px;
        }
    }
}


// Main Panel
// ---------------------------------------------------------------------------

.main-panel {
    @include standard-panel;

    &.with-paper {
        @include media(desktop) {
            margin-top: unit(-($home-paper-offset / 2));
        }
        @include media(tablet) {
            padding-top: unit(1);
        }
    }

    &.with-closing-panel-below {
        padding-bottom: unit(4);
    }

    .contact-page &,
    .thanks-page & {
        @include media(tablet) {
            padding-top: unit(8);
        }
        @include media(mobile) {
            padding-top: unit(4);
            .paper {
                padding-bottom: 0;
            }
        }

        padding-bottom: 0;
    }
}

.closing-panel {
    @include media(desktop) {
        margin-top: unit(4);
        margin-bottom: unit(5);
    }
    @include media(mobile) {
        margin-top: unit(-4);

        text-align: center;
    }
}


// Paper
// ---------------------------------------------------------------------------

.paper {
    // @include move-in-up;
    // max-width: 1020px;
    @include media(mobile) {
        border: none;
    }
    @include media(desktop) {
        padding-right: unit(4);
        padding-bottom: unit(6);
        padding-left: unit(4);
    }

    margin: 0 auto;
    padding-top: unit(2);
    padding-bottom: unit(4);

    border: 1px solid darken($border-color, 20%);
    border-radius: 4px;

    lost-flex-container: row;
    justify-content: center;

    &:hover {
        // border-color: darken($border-color, 20%);
    }
}

.paper-header__intro {
    @include media(tablet) {
        font-size: 18px;
    }

    font-size: 24px;
    font-weight: 600;

    color: $aluminum;
}

.paper-header__title {
    @include media(tablet) {
        font-size: scale(120px, $tablet-scale);

        margin-top: .5rem;
        margin-bottom: .25rem;
    }
    @include media(mobile) {
        font-size: 64px;
    }

    font-family: $title-font;
    font-size: 120px;
    font-weight: 800;
    line-height: 1.125;

    margin-top: 1.5rem;
    margin-bottom: 1rem;

    letter-spacing: -.25rem;
}

.paper-header__summary {
    @include media(tablet) {
        font-size: 18px;
    }

    font-size: 24px;
    font-weight: 600;

    color: $aluminum;
}


.paper-box {
    @include media(mobile) {
        padding: unit(1);
    }
    @include media(tablet) {
        padding: unit(2);
    }

    position: relative;

    padding: unit(4);

    text-align: center;

    &.with-footer {
        padding-bottom: unit(6);
    }

    &.with-photo-grid {
        padding: 0;
    }

    &.is-full {
        lost-column: 1/1 0 0 flex;
    }

    &.is-half {
        @include tablet-single;

        lost-column: 1/2 0 0 flex;
    }

    &.is-third {
        @include mobile-single;
        @include media(tablet) {
            lost-column: 1/2 0 0 flex;
        }

        lost-column: 1/3 0 0 flex;
    }

    &.is-padded {
        padding: unit(8) unit(4);
    }

    &.has-topping {
        @include media(tablet) {
            padding-top: unit(1);
        }

        padding-top: unit(2);
        padding-bottom: unit(2);
    }
}

.paper-box-title {
    @include type-box-title;
}

.paper-box-title--primary {
    font-size: 24px;
}

.paper-box-price {
    font-size: 14px;
    font-weight: 800;

    text-transform: uppercase;

    color: lighten($dove-grey, 25%);
}

.paper-box-footer {
    position: absolute;
    bottom: unit(4);
    left: 0;

    width: 100%;
}

.more-link {
    @include type-small-caps;
}

.circle-icon {
    @include transition(background-color);

    display: flex;

    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: unit(1);

    border-radius: 50%;
    background-color: $primary-color;

    justify-content: center;
    align-items: center;

    &:hover {
        @include spin-icon;

        background-color: $primary-color-hover;
    }

    i {
        font-size: 20px;

        color: $real-white;
    }
}


// Toppings
// ---------------------------------------------------------------------------

$toppings: (
    'topping-a',
    'topping-b',
    'topping-c',
    'topping-d',
    'topping-e',
    'topping-f',
    'topping-g',
    'sauce-a',
    'sauce-b',
    'sauce-c',
    'cheese-a',
    'cheese-b'
);

.burger-topping {
    width: 119px;
    height: 30px;
    margin: 0 auto 1.5rem auto;

    background-repeat: no-repeat;
}

@each $topping in $toppings {
    .#{$topping} {
        background-image: svg-load('../_assets/svg/#{$topping}.svg');
    }
}

$proteins: (
    'bun-top',
    'bun-bottom',
    'meat-a',
    'meat-b',
    'meat-c',
);

.burger-protein {
    @include media(tablet) {
        margin-top: 2rem;
    }

    width: 150px;
    height: 20px;
    margin: 0 auto 2.5rem auto;

    background-repeat: no-repeat;
}

@each $protein in $proteins {
    .protein-#{$protein} {
        background-image: svg-load('../_assets/svg/#{$protein}.svg');

        @if $protein == 'bun-top' {
            height: 61px;
        }

        @if $protein == 'bun-bottom' {
            height: 27px;
        }
    }
}

.is-visible {
    transition: opacity .125s linear;
    animation: bounce-in 1000ms linear both;

    opacity: 1.0;
}

.is-not-visible {
    transition: opacity .125s linear;

    opacity: .0;
}

// Typed
// ---------------------------------------------------------------------------

.typed-cursor {
    @include media(tablet) {
        width: 16px;
        height: 39px;
    }

    position: relative;
    top: 5px;

    display: inline-block;

    width: 25px;
    height: 60px;
    margin-left: .5rem;

    animation: blink 1.25s infinite;

    opacity: 1;
    background-color: white;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    1% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    51% {
        opacity: 1;
    }
}
