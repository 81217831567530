// ===========================================================================
//
// Animations
//
// ===========================================================================


@mixin animated() {
    animation-duration: .325s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;

    animation-fill-mode: both;
}


// Cycle Gradient
// ---------------------------------------------------------------------------

@mixin cycle-gradient {
    animation: cycleGradient 30s ease infinite;

    background-size: 400% 400%;
}

@keyframes cycleGradient {
    0% {
        background-position: 100% 0;
    }
    50% {
        background-position: 0 100%;
    }
    100% {
        background-position: 100% 0;
    }
}


// Spin Icon
// ---------------------------------------------------------------------------

@mixin spin-icon {
    @include animated;

    animation-name: spinIcon;
}

@keyframes spinIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


// Fade In Up
// ---------------------------------------------------------------------------

@mixin fade-in-up() {
    @include animated;

    animation-name: fadeInUp;
}

.fade-in-up {
    @include fade-in-up;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 4%, 0);

        opacity: 0;
    }

    to {
        transform: none;

        opacity: 1;
    }
}


// Move Up
// ---------------------------------------------------------------------------

@mixin move-in-up() {
    @include animated;

    animation-name: moveInUp;
}

.move-in-up {
    @include move-in-up;
}

@keyframes moveInUp {
    from {
        transform: translate3d(0, 4%, 0);
    }

    to {
        transform: none;
    }
}


// Fade In
// ---------------------------------------------------------------------------

@mixin fade-in() {
    @include animated;

    animation-name: fadeIn;
}

.fade-in {
    @include fade-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


// BounceJS
// ---------------------------------------------------------------------------

.bounce-in {
  animation: bounce-in 1000ms linear both;
}

/* Generated with Bounce.js. Edit at https://goo.gl/to5gcl */

@keyframes bounce-in {
  0% { transform: matrix3d(0.5, 0, 0, 0, 0, 0.25, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1); }
  2.72% { transform: matrix3d(0.688, 0, 0, 0, 0, 0.531, 0, 0, 0, 0, 1, 0, 0, 14.825, 0, 1); }
  4.2% { transform: matrix3d(0.775, 0, 0, 0, 0, 0.662, 0, 0, 0, 0, 1, 0, 0, 8.005, 0, 1); }
  5.45% { transform: matrix3d(0.837, 0, 0, 0, 0, 0.755, 0, 0, 0, 0, 1, 0, 0, 2.68, 0, 1); }
  8.17% { transform: matrix3d(0.938, 0, 0, 0, 0, 0.907, 0, 0, 0, 0, 1, 0, 0, -5.659, 0, 1); }
  8.31% { transform: matrix3d(0.942, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, -5.934, 0, 1); }
  10.89% { transform: matrix3d(0.997, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, -8.309, 0, 1); }
  12.51% { transform: matrix3d(1.016, 0, 0, 0, 0, 1.024, 0, 0, 0, 0, 1, 0, 0, -7.605, 0, 1); }
  14.01% { transform: matrix3d(1.026, 0, 0, 0, 0, 1.039, 0, 0, 0, 0, 1, 0, 0, -5.987, 0, 1); }
  16.62% { transform: matrix3d(1.032, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, -2.34, 0, 1); }
  17.06% { transform: matrix3d(1.032, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, -1.735, 0, 1); }
  20.18% { transform: matrix3d(1.026, 0, 0, 0, 0, 1.039, 0, 0, 0, 0, 1, 0, 0, 1.561, 0, 1); }
  23.22% { transform: matrix3d(1.017, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 2.581, 0, 1); }
  27.73% { transform: matrix3d(1.006, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 1.257, 0, 1); }
  29.39% { transform: matrix3d(1.004, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, 0, 0.529, 0, 1); }
  35.56% { transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, -0.802, 0, 1); }
  38.84% { transform: matrix3d(0.999, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, -0.551, 0, 1); }
  47.89% { transform: matrix3d(1, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0.249, 0, 1); }
  60.22% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.077, 0, 1); }
  61.06% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.075, 0, 1); }
  72.55% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.024, 0, 1); }
  80% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.28% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}
