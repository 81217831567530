// ===========================================================================
//
// Global Styles
//
// ===========================================================================


// Body
// ---------------------------------------------------------------------------

html,
body {
    @include media(mobile) {
        overflow-x: hidden;
    }
}

body {
    background-color: $primary-color;

    @if not $responsive {
        @include desktop-width-restriction;
    }
}

body,
button,
input,
select,
textarea {
    @include type-default;

    color: $default-type-color;
}

p,
li {
    line-height: $line-height-loose;

    margin: 1em 0;
}

.app {
}


// Links
// ---------------------------------------------------------------------------

a {
    @include transition;

    color: $white;
    outline: none;
    &:hover {
        color: $real-white;
    }
}


// Titles
// ---------------------------------------------------------------------------

.page-title {
    @include title-default;
    @include media(mobile) {
        font-size: 40px;
    }
    @include media(tablet) {
        font-size: scale(72px, $tablet-scale);
    }

    font-size: 72px;

    margin-bottom: 1rem;
}

.closing-title {
    @extend .page-title;
}

.page-intro {
    @include media(tablet) {
        font-size: 18px;
    }

    font-size: 24px;
    font-weight: 600;

    color: $aluminum;
}

.closing-prefix {
    @extend .page-intro;

    margin-bottom: 1rem;
}

.closing-suffix {
    @extend .page-intro;

    margin-bottom: 2rem;

    color: $white;
}

.page-cta {
    text-align: center;
}


// Buttons
// ---------------------------------------------------------------------------

.button {
    @include type-small-caps;
    @include transition;

    display: inline-block;

    padding: 1em 4em;

    letter-spacing: 2px;

    border: 1px solid darken($border-color, 20%);
    background-color: $primary-color;
    &.is-reversed {
        border-color: $white;
        background-color: $white;

        &:hover {
            color: $real-white;
            border-color: $real-white;
            background-color: transparent;
        }
    }
    &.is-hollow {
        color: $white;
        background-color: transparent;
        &:hover {
            color: $real-white;
            // background-color: $primary-color;
            border-color: $real-white;
        }
    }
}

// Containers
// ---------------------------------------------------------------------------

.container {
    @include outer-container;
}

.container--hero {
    @include media(tablet) {
        padding: 0 unit(2);
    }

    padding: 0 unit(4);

    lost-center: 1060px;
}

.container--closing {
    @extend .container--hero;
}

.container--footer {
    @include media(tablet) {
        padding: 0 unit(2);
    }

    padding: 0 unit(4);


    .home-page & {
        lost-center: 1060px;
    }

    .contact-page &,
    .thanks-page & {
        padding: 0 unit(3);
    }
}

.standard-panel {
    @include standard-panel;
}


// Utility
// ---------------------------------------------------------------------------

.is-hidden {
    display: none;
}

.turbolinks-progress-bar {
    height: 2px;

    background-color: $primary-color;
}
