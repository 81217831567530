
.svg-scroll {
    @include media(tablet) {
        display: none !important;
    }

    position: absolute;
    z-index: 2;
    bottom: -140px;
    left: 50%;

    box-sizing: border-box;
    width: 24px;
    height: 36px;
    margin-left: -12px;

    cursor: pointer;
    transition: opacity .5s;

    opacity: 0;
}

.js-svg-scroll--visible {
    opacity: 1;
}

.svg-scroll__mouse {
    width: 25px;

    transition: opacity .5s;
    vertical-align: middle;

    opacity: 1;
}

.svg-scroll__edge {
    stroke: #9b9b9b;
}

.svg-scroll__wheel {
    animation: updown 1s infinite;

    fill: #9b9b9b;
}

@keyframes updown {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 5px);
    }
    100% {
        transform: translate(0, 0);
    }
}
